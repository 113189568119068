<template>
  <div class="w-full" >
    <vs-row vs-type="flex" vs-w="12" vs-align="center" vs-justify="center" v-if="$atividades.permissoes.includes(14)">
      <vs-col vs-type="flex" vs-w="12" vs-align="center" vs-justify="cendter">
        <vs-row vs-type="flex" vs-w="12" vs-align="center" vs-justify="center">
          <vs-col vs-type="flex" vs-w="6" vs-align="center" vs-justify="center">
            <h2>SOLICITAÇÃO DE SERVIÇO</h2>
          </vs-col>
        </vs-row>
      </vs-col>
      <vs-col vs-type="flex" vs-w="12" vs-align="center" vs-justify="cendter">
        <div class="w-full">
          <vs-row vs-type="flex" vs-w="12" vs-align="start" vs-justify="start">
            <vs-col vs-type="flex" vs-w="3" vs-align="start" vs-justify="start">
              <vx-card class="w-full border" card-background="transparent" no-shadow no-border>
                <div class="w-full">
                  <vs-row vs-type="flex" vs-w="12" vs-align="center" vs-justify="center">
                    <vs-col vs-type="flex" vs-w="12" vs-align="center" vs-justify="center">
                      <vs-textarea
                        height="120"
                        v-validate="'required'"
                        disabled
                        label="AVISOS *"
                        class="w-full my-2 mx-2"
                        value="Você, o aprovador e a equipe de suporte receberão um email com o código da sua solicitação e de todos os detalhes informados. Por enquanto, o retorno será feito através do e-mail suporte@codinarts.com."
                      />
                    </vs-col>
                  </vs-row>
                </div>
              </vx-card>
            </vs-col>
            <vs-col vs-type="flex" vs-w="6" vs-align="start" vs-justify="start">
              <vx-card class="w-full border" no-shadow no-border>
                <div class="w-full">
                  <vs-row vs-type="flex" vs-w="12" vs-align="center" vs-justify="center">
                    <vs-col vs-type="flex" vs-w="11.7" vs-align="center" vs-justify="start">
                      <vs-input
                        label="NOME DA SOLICITAÇÃO *"
                        v-model="solicitacao.nome"
                        class="w-full my-2 mx-2"
                        v-validate="'required'"
                      ></vs-input>
                    </vs-col>
                    <vs-col vs-type="flex" vs-w="0.3" vs-align="end" vs-justify="end">
                      <vx-tooltip
                        color="dark"
                        text="Título da Solicitação. Exemplo: Alteração no Kanban"
                      >
                        <vs-icon style="cursor: pointer" size="20px" icon="info" />
                      </vx-tooltip>
                    </vs-col>
                  </vs-row>
                  <!-- <vs-row vs-type="flex" vs-w="12" vs-align="center" vs-justify="center">
                    <vs-col vs-type="flex" vs-w="6" vs-align="center" vs-justify="start">
                      <div class="w-full my-2 mx-2">
                        <vs-input
                          label="DATA *"
                          v-model="solicitacao.data"
                          class="w-full"
                          disabled
                          type="date"
                          v-validate="'required'"
                        />
                      </div>
                    </vs-col>
                    <vs-col vs-type="flex" vs-w="6" vs-align="center" vs-justify="start">
                      <div class="w-full my-2 mx-2">
                        <vs-input
                          label="NÚMERO *"
                          v-model="solicitacao.id"
                          disabled
                          class="w-full"
                          v-validate="'required'"
                        />
                      </div>
                    </vs-col>
                  </vs-row>
                  <vs-row vs-type="flex" vs-w="12" vs-align="center" vs-justify="center">
                    <vs-col vs-type="flex" vs-w="12" vs-align="center" vs-justify="start">
                      <vs-input
                        label="CLIENTE *"
                        v-model="solicitacao.cliente"
                        class="w-full my-2 mx-2"
                        v-validate="'required'"
                      />
                    </vs-col>
                  </vs-row> -->
                  <vs-row vs-type="flex" vs-w="12" vs-align="center" vs-justify="center">
                    <vs-col vs-type="flex" vs-w="11.7" vs-align="center" vs-justify="start">
                      <div class="w-full my-2 mx-2">
                        <label style="color: black; font-size: 0.85rem">APROVADOR *</label>
                        <el-select
                          filterable
                          v-model="solicitacao.id_aprovador"
                          clearable
                          v-validate="'required'"
                          class="w-full"
                          placeholder="Pesquise"
                          loading-text="Buscando..."
                          :popper-append-to-body="false"
                        >
                          <el-option
                            v-for="colaborador in colaboradores"
                            :value="colaborador.id"
                            :label="colaborador.nome"
                            :key="colaborador.id"
                          ></el-option>
                        </el-select>
                      </div>
                    </vs-col>
                    <vs-col vs-type="flex" vs-w="0.3" vs-align="end" vs-justify="end">
                      <vx-tooltip color="dark" text="Responsável pela solicitação. Exemplo: Thiago">
                        <vs-icon style="cursor: pointer" size="20px" icon="info" />
                      </vx-tooltip>
                    </vs-col>
                  </vs-row>
                  <vs-row vs-type="flex" vs-w="12" vs-align="center" vs-justify="center">
                    <vs-col vs-type="flex" vs-w="12" vs-align="center" vs-justify="start">
                      <vs-input
                        v-validate="'required'"
                        v-model="solicitacao.descricao"
                        label="DESCRIÇÃO DA SOLICITAÇÃO *"
                        class="w-full my-2 mx-2"
                      />
                    </vs-col>
                  </vs-row>
                  <vs-row vs-type="flex" vs-w="12" vs-align="center" vs-justify="center">
                    <vs-col vs-type="flex" vs-w="12" vs-align="center" vs-justify="center">
                      <vs-textarea
                        height="120"
                        v-validate="'required'"
                        v-model="solicitacao.comoFunciona"
                        label="COMO O SISTEMA FUNCIONA HOJE OU ERRO APRESENTADO (OPCIONAL)"
                        class="w-full my-2 mx-2"
                      />
                    </vs-col>
                  </vs-row>
                  <vs-row vs-type="flex" vs-w="12" vs-align="center" vs-justify="center">
                    <vs-col vs-type="flex" vs-w="12" vs-align="center" vs-justify="center">
                      <vs-textarea
                        v-validate="'required'"
                        height="120"
                        v-model="solicitacao.comoDeveria"
                        label="COMO O SISTEMA DEVERIA FUNCIONAR (OPCIONAL)"
                        class="w-full my-2 mx-2"
                      />
                    </vs-col>
                  </vs-row>
                  <vs-row vs-type="flex" vs-w="12" vs-align="center" vs-justify="center">
                    <vs-col vs-type="flex" vs-w="12" vs-align="center" vs-justify="center">
                      <vs-button
                        class="w-full"
                        color="success"
                        type="relief"
                        icon="send"
                        @click="criarTicket()"
                      >ENVIAR SOLICITAÇÃO</vs-button>
                    </vs-col>
                  </vs-row>
                </div>
              </vx-card>
            </vs-col>
          </vs-row>
        </div>
      </vs-col>
    </vs-row>
    <v-row v-else>
        {{ this.$vs.notify({title: "Não autorizado", text: "Você não tem autorização para criar tickets", color: "danger"}) }}
    </v-row>
  </div>
</template>

<script>
export default {
  data() {
    return {
      colaboradores: [],
      solicitacao: {  
        //cliente: "EMOPS " + process.env.VUE_APP_FRANCHISING,
        id_criador: null,
        nome: null,
        comoDeveria: null,
        comoFunciona: null,
        descricao: null,
        id_aprovador: null,
      }
    };
  },
  methods: {
    async montarInfoSolicitacao() {
      //await this.getNumeroSolicitacao();
      await this.getAprovadores();
      this.solicitacao.id_criador = localStorage.getItem("id");
      // const franchise = process.env.NODE_ENV;
      // this.solicitacao.cliente = `EMOPS ${franchise}`;
    },
    // async getNumeroSolicitacao() {
    //   try {
    //     this.solicitacao.id = await this.$http.get("getNumeroSolicitacao");
    //   } catch (err) {
    //     const error = this.$httpErrors(err);
    //     this.$vs.notify(error);
    //   }
    // },
    async getAprovadores() {
      try {
        this.colaboradores = await this.$http.get("getAprovadores");
      } catch (err) {
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }
    },
    // async submitForm() {
    //   this.$validator.validateAll().then(result => {
    //     if (result) {
    //       this.enviarSolicitacao();
    //     } else {
    //       this.$vs.notify({
    //         title: "Preencha todos os campos",
    //         text: "Os campos não devem ser vazios!",
    //         color: "danger"
    //       });
    //     }
    //   });
    // },
    // async enviarSolicitacao() {
    //   this.$vs.loading();
    //   try {
    //     await this.$http.post("enviarSolicitacao", this.solicitacao);
    //     this.$vs.notify({
    //       title: "Solicitação enviada por email!",
    //       text: "Aguarde para receber uma resposta do suporte!",
    //       color: "success"
    //     });
    //     await Object.assign(this.solicitacao, this._beforeEditingCache);
    //     await this.montarInfoSolicitacao();
    //   } catch (err) {
    //     const error = this.$httpErrors(err);
    //     this.$vs.notify(error);
    //   } finally {
    //     this.$vs.loading.close();
    //   }
    // },

    async criarTicket(){
      this.$vs.loading();
      try{
        await this.$http.post("ticket", this.solicitacao);
        this.$vs.notify({
          title: "Sucesso",
          text: "Ticket criado com sucesso",
          color: "success"
        });
        await this.limparFormulario()
      }catch(err){
        const error = this.$httpErrors(err);
        this.$vs.notify(error);
      }finally {
        this.$vs.loading.close();
      }
    },

    async limparFormulario(){
      this.solicitacao.nome = ""
      this.solicitacao.id_aprovador = null
      this.solicitacao.descricao = ""
      this.solicitacao.comoFunciona = ""
      this.solicitacao.comoDeveria = ""
    }
  },
  async mounted() {
    this._beforeEditingCache = Object.assign({}, this.solicitacao);
    await this.montarInfoSolicitacao();
    //this.validarUsuario()
  }
};
</script>

<style>
.actions {
  width: 60px !important;
}
</style>
